<template>
	<div>
		<div :style="{height: height + 'px'}" style="overflow-x: hidden; overflow-y: auto">
			<template v-for="(item, index) in initItems">
				<style-item
						:key="index"
						:index="index"
						:item="item"
						:style-items="styleItems"
						@add="add"
						@remove="remove"/>
			</template>
		</div>
		<div class="text-center">
			<div class="mt-4">
				<v-btn depressed outlined color="primary" @click="$emit('closeDialog')">取消</v-btn>
				<v-btn depressed color="primary" style="margin-left: 20px" @click="save">保存</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import styleItem from "./item.vue";

export default {
	name: "meetingRoomStyle",
	components: {
		styleItem
	},
	data() {
		return {
			initItems: [],
			styleItems: []
		}
	},
	props: {
		height: {
			type: Number,
			default: 0
		},
		items: {
			type: Array,
			default: () => []
		},
		api: {
			type: Object,
			default: ()=> ({})
		},
		sqlid: {
			type: Number,
			default: 0
		}
	},
	methods: {
		save() {
			this.axios.post(this.api.save, {sqlid: this.sqlid, style: JSON.stringify(this.initItems)}).then((res)=> {
				if (res.code === this.staticVal.Code.Success) {
					this.snackbar.success('保存成功');
					this.$emit('closeDialog');
				}
			})
		},
		add() {
			this.initItems.push({})
		},
		remove(i) {
			let items = []
			this.initItems.forEach((item, index) => {
				if (i !== index) {
					items.push(item)
				}
			})
			this.initItems = items
		}
	},
	mounted() {
		this.axios.post(this.select_data, {keyname: 'meetingstyle'}).then((res) => {
					if (res?.data) {
						this.styleItems = res.data[0].values;
					}
				}
		);
	},
	watch: {
		items: {
			handler(v) {
				this.initItems = v?.length > 0 ? v : [{}]
			},
			immediate: true
		}
	}
}
</script>

<style scoped lang="scss">
</style>
