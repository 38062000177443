<template>
	<div>
		<common-table
				:itemScope="['lsell', 'style', 'peitao']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				:rightConfig="rightConfig"
				:leftConfig="leftConfig"
				pageTitle="会议室"
				:listDeploy="listDeploy"
		>
			<template v-slot:lsell="{ item }">
				<w-switches
						inset
						label=""
						color="var(--v-primary-base)"
						@change="updateStatus(item.sqlid)"
						v-model="item.lsell" hide-details dense/>
			</template>

			<template v-slot:style="{ item }">
				<v-btn depressed color="primary" @click="configStyle(item.sqlid)">
					<v-icon class="iconfont mr-1" size="16">iconfont icon-guanli-fill</v-icon>
					布局
				</v-btn>
			</template>

			<template v-slot:peitao="{ item }">
				<v-btn depressed color="primary" @click="configPeitao(item)">
					<v-icon class="iconfont mr-1" size="16">iconfont icon-guanli-fill</v-icon>
					配套
				</v-btn>
			</template>
		</common-table>

		<template v-if="peitaoDialog">
			<exhibition-data-box
					:dialog.sync="peitaoDialog"
					title="选择会议室配套"
					:width="412"
					:contentHeight="650"
			>
				<cascade-checkbox
						:height="600"
						@closeDialog="peitaoDialog = false"
						searchKey="MEETKIT"
						:form="peitaoObject"
						:api="api"/>
			</exhibition-data-box>
		</template>

		<template v-if="styleDialog">
			<exhibition-data-box
					:dialog.sync="styleDialog"
					title="布局风格"
					:width="428"
					:contentHeight="650"
			>
				<style-components
						:height="600"
						:items="styleItems"
						:sqlid="sqlid"
						:api="api"
						@closeDialog="styleDialog = false"
				/>
			</exhibition-data-box>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/meetingPlace.js';
import exhibitionDataBox from '@/components/exhibition-data-box/exhibition-data-box.vue';
import cascadeCheckbox from "@/components/cascadeCheckbox/cascadeCheckbox.vue";
import styleComponents from "./style/style.vue";

export default {
	mixins: [commonCurd],
	components: {
		exhibitionDataBox,
		cascadeCheckbox,
		styleComponents
	},
	data() {
		return {
			api,
			sqlid: '',
			// 排序字段，不填默认sqlid
			sortname: 'seq',
			typeCode: '',
			styleItems: [],
			peitaoDialog: false,
			styleDialog: false,
			peitaoObject: {},
			rightConfig: [
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			leftConfig: [
				{type: 'input', label: '输入会议室代码/名称', property: 'searchkey'},
				{
					type: 'select',
					label: '状态',
					property: 'status',
					items: [{desc: '上架', code: true}, {desc: '不上架', code: false}]
				}
			],
			headers: [
				{text: '会议室代码', value: 'code'},
				{text: '会议室名称', value: 'description'},
				// {text: '会议室面积', value: 'size'},
				{text: '起始价格', value: 'price'},
        // {text: '容纳人数', value: 'people'},
				// {text: '会议室层高', value: 'hight'},
				// {text: '是否有柱', value: 'haspillars'},
				{text: '布局风格', value: 'style'},
				{text: '会议室配套', value: 'peitao'},
				{text: '排序号', value: 'seq'},
				{text: '状态', value: 'lsell'},
				{text: '操作', value: 'actions', width: 120}
			],
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "会议室代码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "会议室名称"
							}
						}
					},
					// {
					// 	cols: 6,
					// 	subassembly: "VTextField",
					// 	property: 'size',
					// 	connector: {
					// 		props: {
					// 			required: true,
					// 			label: "会议室面积"
					// 		}
					// 	}
					// },
					// {
					// 	cols: 6,
					// 	subassembly: "VTextField",
					// 	property: 'people',
					// 	connector: {
					// 		props: {
					// 			required: true,
					// 			label: "容纳人数"
					// 		}
					// 	}
					// },
					// {
					// 	cols: 6,
					// 	subassembly: "VTextField",
					// 	property: 'hight',
					// 	connector: {
					// 		props: {
					// 			required: true,
					// 			label: "会议室层高（m）"
					// 		}
					// 	}
					// },
					// {
					// 	cols: 6,
					// 	subassembly: "WAutocomplete",
					// 	property: 'haspillars',
					// 	connector: {
					// 		props: {
					// 			required: true,
					// 			label: "是否有柱",
					// 			items: [{code: true, desc: '有'}, {code: false, desc: '无'}]
					// 		}
					// 	}
					// },
					// {
					// 	cols: 6,
					// 	subassembly: "VTextField",
					// 	property: 'floor',
					// 	connector: {
					// 		props: {
					// 			required: true,
					// 			label: "所在楼层",
					// 			type: 'number'
					// 		}
					// 	}
					// },
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'price',
						connector: {
							props: {
								required: true,
								label: "起始价格"
							}
						}
					},
          {
            cols: 6,
            subassembly: "VTextField",
            property: 'punit',
            connector: {
              props: {
                required: false,
                label: "价格单位"
              }
            }
          },
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'seq',
						connector: {
							props: {
								label: "排序号码",
								type: 'number'
							}
						}
					},
					{
						cols: 6,
						subassembly: "WSwitches",
						disabledDeleteDefault: true,
						property: 'lsell',
						connector: {
							props: {
								label: '状态',
								inset: true,
								dense: true
							}
						},
						default: true
					},
					// {
					// 	cols: 12,
					// 	subassembly: "MaterialFile",
					// 	property: "mlistpic",
					// 	connector: {
					// 		props: {
					// 			required: true,
					// 			showAddIcon: false,
					// 			hint: "大小不能超过2MB，格式仅限于jpg、png",
					// 			label: "上传图片",
					// 			title: "移动端列表图(142*206)",
					// 			fileType: 'meeting'
					// 		}
					// 	}
					// },
					{
						cols: 12,
						subassembly: 'MaterialFile',
						property: 'mslidepics',
						connector: {
							props: {
								required: true,
								subTitle: '点击添加按钮新增上传区域，拖动选中的文件区域可对其排序，' +
										'图片建议：宽高比例4:3，大小不能超过2MB，格式仅限于jpg、png、jpeg、webp，数量上限为6张',
								label: '上传图片',
								title: '应用端轮播图',
								fileType: 'meeting'
							}
						}
					},
					// {
					// 	cols: 12,
					// 	subassembly: 'MaterialFile',
					// 	property: 'listpic',
					// 	connector: {
					// 		props: {
					// 			required: true,
					// 			showAddIcon: false,
					// 			hint: '大小不能超过2MB，格式仅限于jpg、png',
					// 			label: '上传图片',
					// 			title: '电脑端列表图(1264*713)',
					// 			fileType: 'meeting'
					// 		}
					// 	}
					// },
					// {
					// 	cols: 12,
					// 	subassembly: 'MaterialFile',
					// 	property: 'slidepics',
					// 	connector: {
					// 		props: {
					// 			hint: '大小不能超过2MB，格式仅限于jpg、png',
					// 			label: '上传图片',
					// 			title: '电脑端轮播图(680*340)',
					// 			fileType: 'meeting'
					// 		}
					// 	}
					// },
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '会议室简介'
							}
						}
					},
					{
						cols: 12,
						subassembly: 'VTextarea',
						property: 'richtext',
						connector: {
							props: {
								solo: true,
								rows: 2,
								outlined: true,
								flat: true,
							}
						}
					}
				]
			}
		}
	},
	methods: {
		createdExtend() {
			this.fixedParams.groupid = this.$route.query.value;
		},
		beforeSave(form) {
			form.groupid = this.$route.query.value;
		},
		configStyle(sqlid) {
			this.axios.post(this.api.load, {sqlid: sqlid}).then((res)=> {
				const item = res.data
				if (item.style) {
					this.styleItems = JSON.parse(item.style)
				}
				this.sqlid = sqlid;
				this.styleDialog = true
			})
		},
		configPeitao(item) {
			this.peitaoObject = item;
			this.peitaoDialog = true
		}
	}
}
</script>

<style scoped lang="scss">

</style>
