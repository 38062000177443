<template>
	<v-row :key="index">
		<v-col cols="5">
			<w-autocomplete
					autocomplete="off"
					:items="styleItems"
					@change="item.code = $event"
					:value="item.code"
					:label="'布局风格' + (index + 1)"
			/>
		</v-col>
		<v-col cols="5">
			<v-text-field
					:label="'布局' + (index + 1) + '容纳人数 '"
					v-model="item.people"
			/>
		</v-col>
		<v-col cols="2">
			<template v-if="index === 0">
				<v-btn
						depressed
						color="primary"
						:width="32"
						:min-width="32"
						class="mt-4"
						@click="$emit('add')"
				>
					<v-icon size="16" class="iconfont">
						iconfont icon-zengjia
					</v-icon>
				</v-btn>
			</template>
			<template v-else>
				<v-btn
						@click="remove"
						depressed
						color="redness"
						small
						:width="32"
						:min-width="32"
						class="mt-4">
					<v-icon size="16" color="#ffffff" class="iconfont">
						iconfont icon-shanchu-fill
					</v-icon>
				</v-btn>
			</template>
		</v-col>
	</v-row>
</template>

<script>
import wAutocomplete from '@/components/w-autocomplete/w-autocomplete.vue';

export default {
	name: "styleItem",
	components: {
		wAutocomplete
	},
	props: {
		index: {
			type: Number,
			default: 0
		},
		styleItems: {
			type: Array,
			default: () => []
		},
		item: {
			type: Object,
			default: () => ({})
		}
	},
	methods: {
		remove() {
			this.$emit('remove', this.index)
			// this.$el.remove()
		}
	}
}
</script>

<style scoped>

</style>
